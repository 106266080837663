/**
 * @type {import('vuex').Module}
 */
export var root = {
  state: {
    downloadNum: 0
  },
  getters: {},
  mutations: {
    setDownloadNum(state, num) {
      state.downloadNum = num;
    }
  },
  actions: {}
};
export default root;