import "core-js/modules/es.array.unshift.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
/* 动态 path 匹配例子：https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js */

import router from '@/router';
import Home from '@/views/Home/index.vue';
import Login from '@/views/Login/index.vue';
/**
 * @type {import('vue-router').RouteConfig[]}
 */
export var routes = [{
  path: '/',
  name: 'home',
  meta: {
    title: '易兴软件'
  },
  component: Home
}, {
  path: '/home',
  name: 'home',
  meta: {
    title: '易兴软件'
  },
  component: Home
}, {
  path: '/personalCenter',
  name: 'personalCenter',
  meta: {
    title: '个人中心'
  },
  component: function component() {
    return import( /* webpackChunkName: "personalCenter" */'@/views/PersonalCenter');
  }
}, {
  path: '/about',
  name: 'about',
  meta: {
    title: '关于我们'
  },
  component: function component() {
    return import( /* webpackChunkName: "about" */'@/views/About.vue');
  }
}, {
  path: '/login',
  name: 'login',
  meta: {
    title: '登录注册'
  },
  component: Login
}, {
  path: '/privacy',
  name: 'privacy',
  meta: {
    title: '隐私协议'
  },
  component: function component() {
    return import( /* webpackChunkName: "Privacy" */'@/views/Privacy.vue');
  }
}, {
  path: '/agreement',
  name: 'agreement',
  meta: {
    title: '使用协议'
  },
  component: function component() {
    return import( /* webpackChunkName: "Agreement" */'@/views/Agreement.vue');
  }
}, {
  path: '/*',
  name: '404',
  meta: {
    title: '404'
  },
  component: function component() {
    return import( /* webpackChunkName: "low-priority" */'@/views/404.vue');
  }
}];
if (process.env.VUE_APP_ENABLE_DOCS === 'true') {
  routes.unshift({
    path: '/component-examples',
    name: 'component-examples',
    meta: {
      title: '开发相关文档'
    },
    component: function component() {
      return import('@/components/ComponentExamples/index.vue');
    },
    beforeEnter(to, from, next) {
      if (from.matched.length === 0 && from.path === '/') {
        next();
        return;
      }
      next(false);
      window.open(router.resolve(to.fullPath).href);
    }
  });
}
export default routes;