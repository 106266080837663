import _get from "lodash/get";
/**
 * 这里的配置项及拦截器通常和业务相关
 * 请求拦截器的执行顺序：最后注册--->最先注册
 * 响应拦截器的执行顺序：最先注册--->最后注册--->then
 * 根据顺序做好数据及状态的传递
 */
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import createAxios from './createAxios';
import Vue from 'vue';
import app from '../../main.js';

/**
 * 发送前拦截
 * @param {Parameters<createAxios>[0]} config
 */
var requestHandle = function requestHandle(config) {
  config.headers.Authorization = localStorage.Authorization;
  return config;
};

/* 发送失败拦截 */
var requestErrHandle = function requestErrHandle(err) {
  throw err;
};

/**
 * 响应成功拦截
 * @param {import('axios').AxiosResponse} res
 */
var responseHandle = function responseHandle(res) {
  console.log('响应成功拦截', res.data);
  var _ref = res.data || {},
    status_code = _ref.status_code,
    message = _ref.message;
  // 200 类成功
  if (status_code == '200' || status_code == '201' || /^(arraybuffer|blob|stream)$/.test(_get(res.request, 'responseType'))) {
    return res;
  }
  // 200 类失败
  else {
    var message2 = "".concat(message || '系统错误');
    if (status_code) {
      message2 = "".concat(status_code, " :: ").concat(message);
    }
    if (!res.config.exNoErrorMassage) {
      window.console.error(message2); // TODO: 使用其它组件弹出消息
    }
    var err = new Error(message2);
    err['exRes'] = res;
    throw err;
  }
};

/* 响应失败拦截 */
export var responseErrHandle = function responseErrHandle(error) {
  console.log('error响应拦截对象', error.response);
  if (error.response) {
    // 请求已发出，但服务器响应的状态码不在 2xx 范围内
    console.error('Status:', error.response.status);
    console.error('Data:', error.response.data);
    console.error('Headers:', error.response.headers);
    //权限不足401
    if (error.response.status == 401) {
      localStorage.removeItem('Authorization');
      localStorage.removeItem('userInfo');
      Vue.prototype.$confirm('权限不足', '提示', {
        confirmButtonText: '确定',
        // cancelButtonText: '取消',
        type: 'warning',
        showCancelButton: false,
        showClose: false,
        closeOnClickModal: false
      }).then(function () {
        app.$router.push('/login');
      }).catch(function () {});
    } else if (error.response.status == 431 && localStorage.getItem('Authorization')) {
      //被其他人挤下来了 强制下线
      localStorage.removeItem('Authorization');
      localStorage.removeItem('userInfo');
      //弹出提示框
      Vue.prototype.$confirm('您的账号存在异地登录,如非本人操作,请尽快修改密码', '警告', {
        confirmButtonText: '确定',
        // cancelButtonText: '取消',
        type: 'warning',
        showCancelButton: false,
        showClose: false,
        closeOnClickModal: false
      }).then(function () {
        app.$router.push('/login');
      }).catch(function () {});
    } else if (error.response.status == 432 && localStorage.getItem('Authorization')) {
      //token过期432
      localStorage.removeItem('Authorization');
      localStorage.removeItem('userInfo');
      //弹出提示框
      Vue.prototype.$confirm('您的登录信息已过期,请重新登录', '提示', {
        confirmButtonText: '确定',
        // cancelButtonText: '取消',
        type: 'warning',
        showCancelButton: false,
        showClose: false,
        closeOnClickModal: false
      }).then(function () {
        app.$router.push('/login');
      }).catch(function () {});
    }
    if (error.response.data.errors) {
      // 获取对象的所有 key
      var keys = Object.keys(error.response.data.errors);

      // 找到第一个值为数组的 key
      var firstArrayKey;
      for (var _i = 0, _keys = keys; _i < _keys.length; _i++) {
        var key = _keys[_i];
        if (Array.isArray(error.response.data.errors[key])) {
          firstArrayKey = key;
          break;
        }
      }

      // 如果存在值为数组的 key，则获取该 key 对应数组的第一项
      var result = firstArrayKey ? error.response.data.errors[firstArrayKey][0] : error.response.data.message;
      Vue.prototype.$message.error(result);
    } else if (error.response.status == 431) {} else {
      Vue.prototype.$message.error(error.response.data.message);
    }
  } else if (error.request) {
    // 请求已发出，但没有收到响应
    console.error('No response received:', error.request);
    Vue.prototype.$message.error('服务器未响应');
  } else {
    // 在设置请求时触发错误
    console.error('Error setting up the request:', error.message);
    Vue.prototype.$message.error('设置请求出错');
  }
  // 非 200 类失败 (有响应 & 响应体解析后是 json 对象)
  // if (error.response && _.isPlainObject(error.response.data)) {
  //   if (!_.get(error.config, 'exNoErrorMassage')) {
  //     const status_code = _.get(error.response.data, 'status_code')
  //     let message = _.get(error.response.data, 'message') || '系统错误'
  //     if (status_code) {
  //       message = `${status_code} :: ${message}`
  //     }
  //     window.console.error(message) // TODO: 使用其它组件弹出消息
  //   }
  // }
  throw error;
};
export var http = createAxios({
  baseURL: process.env.VUE_APP_ENV === 'stage' ? localStorage.baseurl_api || process.env.VUE_APP_BASEURL_API // stage 环境客户端侧允许自定义接口前缀，方便调试（特别是后端开发）
  : process.env.VUE_APP_BASEURL_API
}, function (instance) {
  instance.interceptors.request.use(requestHandle, requestErrHandle);
  instance.interceptors.response.use(responseHandle, responseErrHandle);
});
export default http;