import _objectSpread from "C:/Users/fb002/Desktop/test1218/vue-cli-template/node_modules/.store/@babel+runtime@7.23.9/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import Vue from 'vue';
import Vuex from 'vuex';
import root from './root';
Vue.use(Vuex);

/**
 * 全局唯一 Store 实例
 */
export var store = new Vuex.Store(_objectSpread(_objectSpread({
  devtools: process.env.VUE_APP_ENV === 'dev' || process.env.VUE_APP_ENV === 'stage',
  strict: process.env.VUE_APP_ENV === 'dev'
}, root), {}, {
  /* module 应该启用命名空间，即 namespaced: true */
  modules: {}
}));
export default store;