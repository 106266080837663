import _each from "lodash/each";
import _union from "lodash/union";
import _uniqueId from "lodash/uniqueId";
import _noop from "lodash/noop";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.freeze.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.for-each.js";
import axios from 'axios';
var CancelToken = axios.CancelToken,
  Cancel = axios.Cancel;
var tokens = {};

/**
 * 请求前处理 config（尽可能在最先注册的拦截器中调用）
 * @param {import('axios').AxiosRequestConfig} config
 */
export var setConfig = function setConfig(config) {
  var method = config.method,
    _config$baseURL = config.baseURL,
    baseURL = _config$baseURL === void 0 ? '' : _config$baseURL,
    url = config.url,
    exCancelName = config.exCancelName,
    cancelToken = config.cancelToken;
  var path = url.replace(/\?.*$/, '');
  var name = "".concat(method).concat(baseURL).concat(path);
  if (exCancelName === true) name = "".concat(method).concat(baseURL).concat(path);else if (/^\//.test(exCancelName)) name = "".concat(method).concat(baseURL).concat(exCancelName);else if (typeof exCancelName === 'string' && exCancelName) name = exCancelName;
  var token, _promise;
  if (cancelToken instanceof CancelToken) {
    token = cancelToken;
    _promise = cancelToken.promise;
  } else {
    token = new CancelToken(_noop);
  }
  token.promise = new Promise(function (resolve) {
    _promise && _promise.then(resolve).catch(function (e) {
      return e;
    });
    token._exCancel_resolvePromise = resolve;
  });
  token._exCancel_id = _uniqueId("".concat(Date.now()));
  config._exCancel.id = token._exCancel_id;
  config._exCancel.name = name;
  config.cancelToken = token;
  tokens[name] = tokens[name] || [];
  tokens[name].push(token);
};

/**
 * 钩子函数
 * @type {import('axios').AxiosInstance['exHooks'][0]}
 */
export var hooks = Object.freeze({
  onBefore(config) {
    config._exCancel = {}; // 钩子与拦截器之间的通信对象
    if (config.exCancel) {
      var method = config.method,
        _config$baseURL2 = config.baseURL,
        baseURL = _config$baseURL2 === void 0 ? '' : _config$baseURL2,
        url = config.url,
        exCancel = config.exCancel;
      var path = url.replace(/\?.*$/, '');
      var names = Array.isArray(exCancel) ? exCancel : [exCancel];
      names = _union(names).map(function (name) {
        if (name === true) return "".concat(method).concat(baseURL).concat(path);
        if (/^\//.test(name)) return "".concat(method).concat(baseURL).concat(name);
        if (typeof name === 'string') return name;
      });
      names = names.filter(Boolean);
      cancel(names);
    }
  },
  onComplete(config) {
    var _config$_exCancel = config._exCancel,
      id = _config$_exCancel.id,
      name = _config$_exCancel.name;
    var arr = tokens[name];
    if (id && arr) {
      var index = arr.findIndex(function (token) {
        return token._exCancel_id === id;
      });
      if (index > -1) {
        arr.splice(index, 1);
        if (arr.length === 0) delete tokens[name];
      }
    }
  }
});

/**
 * 取消未完成的请求
 * @param {string | string[]} name exCancelName 参数值对应的名称
 */
export var cancel = function cancel(name) {
  var names = Array.isArray(name) ? name : [name];
  _each(tokens, function (val, key) {
    if (names.includes(key)) {
      val.forEach(function (token) {
        if (token.reason) return;
        token.reason = new Cancel('axios_requestConfig_exCancel');
        token._exCancel_resolvePromise(token.reason);
      });
      delete tokens[key];
    }
  });
};