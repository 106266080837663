import "vant/lib/toast/style/less.js";
import _Toast from "vant/lib/toast";
import _Loading2 from "element-ui/lib/theme-chalk/loading.css";
import "element-ui/lib/theme-chalk/base.css";
import _Loading from "element-ui/lib/loading";
import "core-js/modules/es.object.freeze.js";
// @PC.element-ui
// @H5.vant

var instance = null; // 单例模式
var count = 0;

/**
 * @type {import('axios').AxiosInstance['exHooks'][0]}
 */
export var exShowLoading = Object.freeze({
  onBefore(config) {
    if (config.exShowLoading) {
      // @PC.element-ui
      if (!instance || instance.visible === false) {
        instance = _Loading.service();
      }
      // @H5.vant
      if (!instance || instance.value === false) {
        instance = _Toast.loading({});
      }
      count++;
      config._exShowLoading = true;
    }
  },
  onComplete(config) {
    if (config._exShowLoading) {
      if (instance) {
        count--;
        if (count <= 0) {
          instance.close();
          instance = null;
          count = 0;
        }
      }
    }
  }
});
export default exShowLoading;