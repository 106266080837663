/**
 * @param {import('vue-router').default} router
 */
export default function (router) {
  if (router._registerInterceptor) return;
  router._registerInterceptor = true;

  /* 页面标题处理 */
  router.afterEach(function (to) {
    var title = to.meta.title;
    title = typeof title === 'function' ? title(to) : title;
    if (title) {
      document.title = title;
    }
  });
}