import _objectSpread from "C:/Users/fb002/Desktop/test1218/vue-cli-template/node_modules/.store/@babel+runtime@7.23.9/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _uniqueId from "lodash/uniqueId";
import _each from "lodash/each";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import mergeConfig from 'axios/lib/core/mergeConfig';
var merge = function merge(defaults, config) {
  config = mergeConfig(defaults, config);
  if (config.method) {
    config.method = config.method.toLowerCase();
  } else if (defaults.method) {
    config.method = defaults.method.toLowerCase();
  } else {
    config.method = 'get';
  }
  return config;
};
var fireBefore = function fireBefore(exHooks, config) {
  exHooks.forEach(function (_ref) {
    var onBefore = _ref.onBefore;
    return onBefore && onBefore(config);
  });
};
var fireComplete = function fireComplete(exHooks, config, isResolve, resOrErr) {
  exHooks.forEach(function (_ref2) {
    var onComplete = _ref2.onComplete;
    return onComplete && onComplete(config, isResolve, resOrErr);
  });
};
var complete = function complete(promise, exHooks, config) {
  promise.then(function (res) {
    return fireComplete(exHooks, config, true, res);
  }).catch(function (e) {
    return e;
  });
  promise.catch(function (err) {
    return fireComplete(exHooks, config, false, err);
  });
  return promise.catch(function (err) {
    throw err;
  });
};

/**
 * 包装所有请求方法，实现相关钩子
 * @typedef {import('axios').AxiosInstance} AxiosInstance
 * @param {AxiosInstance} instance 输入旧的实例
 * @returns {AxiosInstance} 输出新的实例
 */
export default function wrapAxios(instance) {
  var wrap = function wrap() {
    return wrap.request.apply(wrap, arguments);
  };
  _each(instance, function (val, key) {
    wrap[key] = val;
  });
  wrap.exHooks = [];
  wrap.exHooks.add = function (obj) {
    var _this = this;
    var _id = _uniqueId("".concat(Date.now()));
    this.push(_objectSpread(_objectSpread({}, obj), {}, {
      _id
    }));
    return function () {
      var index = _this.findIndex(function (o) {
        return o._id === _id;
      });
      index > -1 && _this.splice(index, 1);
    };
  };
  wrap.request = function (config) {
    if (typeof config === 'string') {
      config = arguments[1] || {};
      config.url = arguments[0];
    } else {
      config = config || {};
    }
    config = merge(this.defaults, config);
    fireBefore(this.exHooks, config);
    var promise = instance.request.call(this, config);
    return complete(promise, this.exHooks, config);
  };
  var _loop = function _loop() {
    var method = _arr[_i];
    wrap[method] = function (url, config) {
      config = merge(this.defaults, _objectSpread(_objectSpread({}, config), {}, {
        method,
        url
      }));
      fireBefore(this.exHooks, config);
      var promise = instance[method].call(this, url, config);
      return complete(promise, this.exHooks, config);
    };
  };
  for (var _i = 0, _arr = ['delete', 'get', 'head', 'options']; _i < _arr.length; _i++) {
    _loop();
  }
  var _loop2 = function _loop2() {
    var method = _arr2[_i2];
    wrap[method] = function (url, data, config) {
      config = merge(this.defaults, _objectSpread(_objectSpread({}, config), {}, {
        method,
        url
      }));
      fireBefore(this.exHooks, config);
      var promise = instance[method].call(this, url, data, config);
      return complete(promise, this.exHooks, config);
    };
  };
  for (var _i2 = 0, _arr2 = ['post', 'put', 'patch']; _i2 < _arr2.length; _i2++) {
    _loop2();
  }
  return wrap;
}