import "C:\\Users\\fb002\\Desktop\\test1218\\vue-cli-template\\node_modules\\.store\\core-js@3.36.0\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "C:\\Users\\fb002\\Desktop\\test1218\\vue-cli-template\\node_modules\\.store\\core-js@3.36.0\\node_modules\\core-js\\modules\\es.promise.js";
import "C:\\Users\\fb002\\Desktop\\test1218\\vue-cli-template\\node_modules\\.store\\core-js@3.36.0\\node_modules\\core-js\\modules\\es.object.assign.js";
import "C:\\Users\\fb002\\Desktop\\test1218\\vue-cli-template\\node_modules\\.store\\core-js@3.36.0\\node_modules\\core-js\\modules\\es.promise.finally.js";
import 'normalize.css';
import './styles/reset.less';
import Vue from 'vue';
import './catchError';
import './element-ui'; // @PC.element-ui
import './vant'; // @H5.vant
import './styles/global.less';
import router from './router';
import store from './store';
import './injects';
import App from './App.vue';
// 导入顶部导航栏
import TopNav from '@/components/TopNav';
// 导入底导航栏
import BottomNav from '@/components/BottomNav';
// 导入百度地图
import BaiduMap from 'vue-baidu-map';
// 导入css动画库
import 'animate.css';
// 导入封装的api请求
import http from './scripts/http';
import common from './scripts/common';

/* 条件编译 (必须是运行时可用的环境变量，并且变量值不能为 undefined，否则模块必定会打包) */
if (process.env.VUE_APP_MOCK === 'true') {
  require('./api/mock');
}
if (process.env.VUE_APP_ENV === 'dev' || process.env.VUE_APP_ENV === 'stage') {
  require('./vconsole'); // @H5
}
Vue.config.devtools = process.env.VUE_APP_ENV === 'dev' || process.env.VUE_APP_ENV === 'stage';
Vue.config.silent = process.env.VUE_APP_ENV === 'prod';
Vue.config.productionTip = false;

//注册全局组件
Vue.component('TopNav', TopNav);
Vue.component('BottomNav', BottomNav);
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'ny3wFiqIhtNziAWTt66tLwo8Iz33YN29'
});
Vue.prototype.$http = http;
Vue.use(common);
export default new Vue({
  router,
  store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');