import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.freeze.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import Vue from 'vue';
var checkName = function checkName(name) {
  var keys = Array.isArray(name) ? name : [name];
  var errKeys = keys.filter(function (k) {
    return /^hook:/.test(k);
  });
  if (errKeys.length > 0) {
    throw new Error("\u4E8B\u4EF6\u540D ".concat(errKeys, " \u4E0D\u7B26\u5408\u89C4\u8303"));
  }
};
export var createBus = function createBus() {
  var vueIns = new Vue();
  var bus = Object.freeze({
    /**
     * @param  {Parameters<vueIns['$on']>} args
     */
    on() {
      checkName(arguments.length <= 0 ? undefined : arguments[0]);
      vueIns.$on.apply(vueIns, arguments);
      return bus;
    },
    /**
     * @param  {Parameters<vueIns['$once']>} args
     */
    once() {
      checkName(arguments.length <= 0 ? undefined : arguments[0]);
      vueIns.$once.apply(vueIns, arguments);
      return bus;
    },
    /**
     * @param  {Parameters<vueIns['$off']>} args
     */
    off() {
      checkName(arguments.length <= 0 ? undefined : arguments[0]);
      vueIns.$off.apply(vueIns, arguments);
      return bus;
    },
    /**
     * @param  {Parameters<vueIns['$emit']>} args
     */
    emit() {
      checkName(arguments.length <= 0 ? undefined : arguments[0]);
      vueIns.$emit.apply(vueIns, arguments);
      return bus;
    },
    get _events() {
      return vueIns._events;
    }
  });
  return bus;
};
export default createBus();