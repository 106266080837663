import _objectSpread from "C:/Users/fb002/Desktop/test1218/vue-cli-template/node_modules/.store/@babel+runtime@7.23.9/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _each from "lodash/each";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/web.btoa.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-exception.constructor.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.dom-exception.to-string-tag.js";
import axios from 'axios';
import stringify from 'qs/lib/stringify';
import dateFns_format from 'date-fns/format';
import download from './download';
import Vue from 'vue';
export { download // 附件下载
};

/**
 * 将列表转成对象
 * @param {object[]} list
 * @param {string} [valueKey]
 * @param {string} [labelKey]
 * @returns {object}
 */
export var listToMap = function listToMap(list, valueKey, labelKey) {
  valueKey = valueKey || 'value';
  labelKey = labelKey || 'label';
  var map = {};
  _each(list, function (item) {
    map[item[valueKey]] = item[labelKey];
  });
  return map;
};

/**
 * 时间格式化
 * @param {string | number | Date} date
 * @param {string} [format] 官方文档：https://date-fns.org/v1.30.1/docs/format#description
 * @example dateFormat('YYYY年MM月DD日 HH时mm分ss秒SSS毫秒 Z时区 Q季度 X秒时间戳 x毫秒时间戳')
 */
export var dateFormat = function dateFormat(date) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'YYYY-MM-DD';
  if (!date) return '';
  if (format === '@iso') format = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
  return dateFns_format(date, format);
};

/**
 * 将对象序列化成参数
 * @param {object} data
 * @param {Parameters<qs.stringify>[1]} [options]
 */
export var qsStringify = function qsStringify(data, options) {
  options = _objectSpread({
    arrayFormat: 'repeat'
  }, options);
  return stringify(data, options);
};

/**
 * 将对象转成 formData
 * @typedef {string | number | boolean | File | Blob} Val
 * @param {{[key: string]: Val | Val[]}} data
 * @param {'repeat' | 'brackets' | 'indices'} [arrayFormat]
 */
export var toFormData = function toFormData(data) {
  var arrayFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'repeat';
  if (data instanceof FormData) return data;
  var formData = new FormData();
  _each(data, function (val, key) {
    if (val === undefined) return;
    if (Array.isArray(val)) {
      val = val.filter(function (v) {
        return v !== undefined;
      });
      val.forEach(function (v, i) {
        var k = key;
        if (arrayFormat === 'brackets') k += '[]';else if (arrayFormat === 'indices') k += "[".concat(i, "]");
        formData.append(k, v === null ? '' : v);
      });
    } else {
      formData.append(key, val === null ? '' : val);
    }
  });
  return formData;
};

/**
 * 判断是否为 axios 取消的请求
 */
export var isCancel = axios.isCancel;

/**
 * 防抖函数
 */
// debounce.js

export var debounce = function debounce(func, delay) {
  var timeoutId;
  return function () {
    var _this = this;
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    // 如果之前设置过定时器，则清除之
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // 设置一个新的定时器，延迟执行传入的函数
    timeoutId = setTimeout(function () {
      func.apply(_this, args);
    }, delay);
  };
};

/**
 * 判断用户是否登录
 */
export var userIsLogin = function userIsLogin() {
  // 从 localStorage 或其他存储地方获取用户的身份验证令牌
  var Authorization = localStorage.getItem('Authorization');

  // 如果身份验证令牌存在且不为空，则认为用户已登录
  return Authorization && Authorization !== '';
};

/**
 * 设置cookie()
 **/
export var setCookie = function setCookie(name, value, day) {
  var date = new Date();
  date.setDate(date.getDate() + day);

  //base64加密()
  var baseScrect = window.btoa(value);
  document.cookie = name + '=' + baseScrect + ';expires=' + date;
};

/**
* 获取cookie(分为对称加密与非对称加密两种方式 aes加密)
**/
export var getCookie = function getCookie(name) {
  // MD5加密方式
  var reg = RegExp(name + '=([^;]+)');
  var arr = document.cookie.match(reg);
  if (arr) {
    return arr[1];
  } else {
    return '';
  }
};

/**
* 删除cookie(加密方式分为对称加密和非对称)
**/
export var delCookie = function delCookie(name) {
  setCookie(name, null, -1);
};